import Cookies from "js-cookie";
import MyAxios from "../utils/helpers/myAxios";
import { ErrorResponse } from "../utils/values/constants";
import { SessionKey } from "../utils/values/constants";
const chapterService = (() => {
  const autoSave = async ({
    title,
    storyId,
    status,
    saveDraft,
    fullText,
    type,
    chapterId,
  }) => {
    try {
      const params = {
        title: title,
        story_id: storyId,
        status: status,
        save_draft: saveDraft,
        full_text: fullText,
        type: type,
        chapter_id: chapterId,
        created_on: "web",
      };
      const response = await MyAxios.post("/api/chapter/autosave", params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const upsertChapter = async ({
    title,
    storyId,
    status,
    saveDraft,
    fullText,
    type,
    media,
    chapterId,
    wordsCount,
  }) => {
    try {
      const params = {
        title: title,
        story_id: storyId,
        status: status,
        save_draft: saveDraft,
        full_text: fullText,
        type: type,
        caption: "",
        media: media,
        chapter_id: chapterId,
        created_on: "web",
        words_count: wordsCount,
      };
      const response = await MyAxios.post("/api/chapter/upsert", params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const editChapter = async ({ storyId, chapterId }) => {
    try {
      const response = await MyAxios.get(
        `/api/chapter/edit/${storyId}/${chapterId}`
      );
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const postSetCostChapter = async ({ storyId, chapterId, type, amount }) => {
    try {
      const params = {
        story: storyId,
        chapter: chapterId,
        type: type,
        amount: parseInt(amount),
        updated_on: "apps",
      };
      const response = await MyAxios.post(`api/chapter/set/cost`, params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const getMyListChapter = async ({ storyId, pageNumber }) => {
    try {
      const response = await MyAxios.get(
        `/api/story/author/${storyId}/chapter`
      );
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const getCostUnlockChapter = async ({ chapterId }) => {
    try {
      const response = await MyAxios.get(`/api/chapter/info/cost/${chapterId}`);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const deleteChapter = async ({ storyId, chapterId }) => {
    try {
      const params = {
        story: storyId,
      };
      const response = await MyAxios.post(
        `/api/chapter/delete/${chapterId}`,
        params
      );
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const postDeleteRedis = async ({ storyId }) => {
    try {
      const userId = Cookies.get(SessionKey.userId);
      const params = {
        story: storyId,
        user: userId,
      };
      const response = await MyAxios.post("/api/chapter/redis/remove", params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  return {
    autoSave: autoSave,
    upsertChapter: upsertChapter,
    editChapter: editChapter,
    deleteChapter: deleteChapter,
    postDeleteRedis: postDeleteRedis,
    getMyListChapter: getMyListChapter,
    postSetCostChapter: postSetCostChapter,
    getCostUnlockChapter: getCostUnlockChapter,
  };
})();

export default chapterService;
