import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getMyprofile } from "../../features/profile/myProfileSlice";
import ItemMenuEditMyStory from "../../pages/myStory/form/itemMenuEditMyStory";
import { SessionKey } from "../values/constants";
import { signOut, getAuth } from "firebase/auth";
import { firebaseConfig } from "../helpers/firebaseConfig";
import { initializeApp } from "@firebase/app";
import Cookies from "js-cookie";
const CircleProfile = () => {
  const dispatch = useDispatch();
  const [profileImg, setProfileImg] = useState(
    Cookies.get(SessionKey.profileImg)
  );
  const profileState = useSelector((state) => state.myProfile);
  useEffect(() => {
    const profileImg = Cookies.get(SessionKey.profileImg);
    if (profileImg === undefined) {
      dispatch(getMyprofile());
    }
  }, [dispatch]);

  useEffect(() => {
    setProfileImg(Cookies.get(SessionKey.profileImg));
  }, [profileState]);

  const onLogout = () => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    signOut(auth);
    Cookies.remove(SessionKey.profileImg);
    Cookies.remove(SessionKey.profileName);
    Cookies.remove(SessionKey.token);
    Cookies.remove(SessionKey.userId);
    const kbmLink = process.env.REACT_APP_REDIRECT_KBM;
    const redirectLink = `${kbmLink}/logout-write`;
    window.location.replace(redirectLink);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      trigger="click"
      overlay={(props) => (
        <Popover
          {...props}
          style={{
            position: "absolute",
            ...props.style,
          }}
        >
          <Popover.Header
            as="h3"
            style={{
              backgroundColor: "#20BF6B",
              color: "white",
              textAlign: "center",
            }}
          >
            {Cookies.get(SessionKey.profileName)}
          </Popover.Header>
          <Popover.Body>
            <ItemMenuEditMyStory
              src="/assets/exit.png"
              onClick={onLogout}
              title="Keluar"
            />
          </Popover.Body>
        </Popover>
      )}
    >
      <img
        src={profileImg}
        alt="profile img"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/assets/PROFILE.png";
        }}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "100%",
          margin: "auto 0 auto 20px",
        }}
      />
    </OverlayTrigger>
  );
};

export default CircleProfile;
