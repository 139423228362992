import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileService from "../../services/profileService";
import { SessionKey } from "../../utils/values/constants";
import Cookies from "js-cookie";
const name = "profile";

export const getMyprofile = createAsyncThunk(`${name}/myProfile`, async () => {
  return await profileService.getMyProfile();
});

const myProfileSlice = createSlice({
  name: name,
  initialState: {
    isLoading: false,
    status: false,
    data: null,
  },
  extraReducers: {
    [getMyprofile.pending](state) {
      state.isLoading = true;
      state.status = false;
      state.data = null;
    },
    [getMyprofile.fulfilled](state, { payload }) {
      state.isLoading = true;
      state.status = false;
      state.data = payload.data.user;
      Cookies.set(
        SessionKey.profileImg,
        state.data.profile_img.url
      );
      Cookies.set(SessionKey.profileName, state.data.full_name);
    },
    [getMyprofile.rejected](state, { error }) {
      state.isLoading = true;
      state.status = false;
      state.data = null;
    },
  },
});

export default myProfileSlice.reducer;
