import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "./authService";
import { SessionKey } from "../../utils/values/constants";
import { toast } from "react-toastify";
import { initializeApp } from "@firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseConfig } from "../../utils/helpers/firebaseConfig";
import Cookies from "js-cookie";

const initialState = {
  isLoading: false,
  status: false,
  message: "",
  data: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, { dispatch }) => {
    const response = await AuthService.postLogin({
      username: username,
      password: password,
    });
    return response.data;
  }
);

export const validationRecaptcha = createAsyncThunk(
  "auth/validationRecaptcha",
  async ({ token }) => {
    const response = await AuthService.validationRecaptcha({
      token: token,
    });
    return response.data;
  }
);

export const postCheckLogin = createAsyncThunk(
  `auth/checkLogin`,
  async (token) => {
    const response = await AuthService.checkLogin(token);
    return response.data;
  }
);

export const postGenerateToken = createAsyncThunk(
  `auth/postGenerateToken`,
  async () => {
    const response = await AuthService.postGenerateToken();
    return response.data;
  }
);

export const postloginGoogle = createAsyncThunk(
  "auth/login",
  async ({ uid, name, profileImg, email, token }) => {
    const response = await AuthService.postLoginGoogle({
      uid: uid,
      name: name,
      profileImg: profileImg,
      email: email,
      token: token,
    });
    return response.data;
  }
);

export const loginGoogle = createAsyncThunk(`auth/googleLogin`, async () => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  return await signInWithPopup(auth, provider);
});

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: {
    [login.pending](state) {
      state.isLoading = true;
    },
    [login.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.status = payload.status;
      state.message = payload.message;
      Cookies.set(SessionKey.userId, payload.data.user.user_id);
      Cookies.set(
        SessionKey.token,
        payload.data.user.auth.access_token
      );
      //MyAxios.defaults.headers['Authorization'] = `Bearer ${payload.data.user.auth.access_token}`
      window.location.replace("/");
      toast.success(payload.message, {
        theme: "colored",
      });
    },
    [login.rejected](state, { error }) {
      state.isLoading = false;
      state.status = error.status;
      state.message = error.message;
      toast.error(error.message, {
        theme: "colored",
      });
    },
    [validationRecaptcha.pending](state) {
      state.isLoading = true;
    },
    [validationRecaptcha.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.status = true;
      state.data = payload;
    },
    [validationRecaptcha.rejected](state, { error }) {
      state.isLoading = false;
      state.status = false;
      toast.error(error.message, {
        theme: "colored",
      });
    },
    [postCheckLogin.pending](state) {
      state.isLoading = true;
    },
    [postCheckLogin.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.status = payload.status;
      state.message = payload.message;
      Cookies.set(SessionKey.userId, payload.data.user_id);
      Cookies.set(SessionKey.token, payload.data.bearer);
      //MyAxios.defaults.headers['Authorization'] = `Bearer ${payload.data.user.auth.access_token}`
      window.location.replace("/");
    },
    [postCheckLogin.rejected](state, { error }) {
      state.isLoading = false;
      state.status = error.status;
      state.message = error.message;
      window.location.replace("/login");
    },
    [postGenerateToken.pending](state) {
      state.isLoading = true;
    },
    [postGenerateToken.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.status = payload.status;
      state.message = payload.message;
      const baseUrl_redirect = process.env.REACT_APP_REDIRECT_KBM;
      window.location.replace(
        `${baseUrl_redirect}/login?token=${payload.data.token}`
      );
    },
    [postGenerateToken.rejected](state, { error }) {
      state.isLoading = false;
      state.status = error.status;
      state.message = error.message;
      // window.location.replace("/login");
    },
    [postloginGoogle.pending](state) {
      state.isLoading = true;
    },
    [postloginGoogle.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.status = payload.status;
      state.message = payload.message;
      if (payload.data.is_logged == false) {
        toast.error(payload.message, {
          theme: "colored",
        });
        setTimeout(() => {
          window.location.href = "https://kbm.id/register";
        }, 1500);
      } else {
        Cookies.set(
          SessionKey.userId,
          payload.data.user.user_id
        );
        Cookies.set(
          SessionKey.token,
          payload.data.user.auth.access_token
        );
        //MyAxios.defaults.headers['Authorization'] = `Bearer ${payload.data.user.auth.access_token}`
        window.location.replace("/");
        toast.success(payload.message, {
          theme: "colored",
        });
      }
    },
    [postloginGoogle.rejected](state, { error }) {
      state.isLoading = false;
      state.status = false;
      state.message = error.message;
      toast.error(error.message, {
        theme: "colored",
      });
    },
  },
  [loginGoogle.pending](state) {
    state.isLoading = true;
  },
  [loginGoogle.fulfilled](state, { payload }) {
    state.isLoading = false;
    state.status = true;
    state.data = payload;
  },
  [loginGoogle.rejected](state, { error }) {
    state.isLoading = false;
    state.status = false;
    if (error.code !== "auth/popup-closed-by-user") {
      var errorMessage = error.message;
      if (error.code === "auth/network-request-failed") {
        errorMessage =
          "Oops, terjadi kesalahan. Mohon coba beberapa saat lagi.";
      } else if (error.code === "auth/internal-error") {
        errorMessage = "Network error";
      }
      toast.error(errorMessage, {
        theme: "colored",
      });
    }
  },
});

export default authSlice.reducer;
