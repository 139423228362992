import Cookies from "js-cookie";
import MyAxios from "../utils/helpers/myAxios";
import { SessionKey } from "../utils/values/constants";
import { ErrorResponse } from "../utils/values/constants";
const myStoryService = (() => {
  const getMyStoriesDraft = async ({ pageNumber }) => {
    try {
      const userId = Cookies.get(SessionKey.userId);
      const params = {
        page_number: pageNumber,
        page_size: 10,
      };
      const response = await MyAxios.get(`/api/profile/${userId}/story/draft`, {
        params,
      });
      if (!response.data.status) {
        return Promise.reject(response.data);
      }

      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const getMyStoriesRelease = async ({ pageNumber }) => {
    try {
      const userId = Cookies.get(SessionKey.userId);
      const params = {
        page_number: pageNumber,
        page_size: 10,
        query: "",
      };
      const response = await MyAxios.get(
        `/api/profile/${userId}/story/released`,
        { params }
      );
      if (!response.data.status) {
        return Promise.reject(response.data);
      }

      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const upsertStory = async ({
    storyId,
    title,
    category,
    tags,
    synopsis,
    coverImg,
    isShowWordCount,
  }) => {
    try {
      const params = {
        story_id: storyId,
        title: title,
        category: category,
        tags: tags,
        synopsis: synopsis,
        cover_img: coverImg,
        attribute_read_time: "active",
        attribute_view_count: "active",
        attribute_words_count: isShowWordCount,
      };
      const response = await MyAxios.post("/api/story/upsert", params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const getEditStory = async (storyId) => {
    try {
      const response = await MyAxios.get(`/api/story/edit/new/${storyId}`);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }

      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const changeSeqChapter = async (chapters) => {
    try {
      const response = await MyAxios.post("/api/chapter/change-seq", chapters);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }

      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const monetizeStory = async (storyId) => {
    try {
      const params = {
        id: storyId,
      };
      const response = await MyAxios.post("/api/story/monetize", params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }

      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const deleteStory = async (storyId) => {
    try {
      const response = await MyAxios.post(`/api/story/delete/${storyId}`);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const completeStory = async (storyId) => {
    try {
      const params = {
        id: storyId,
      };
      const response = await MyAxios.post(`/api/story/set-completed`, params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const unCompleteStory = async (storyId) => {
    try {
      const params = {
        id: storyId,
      };
      const response = await MyAxios.post(`/api/story/set-uncompleted`, params);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  return {
    getMyStoriesDraft: getMyStoriesDraft,
    getMyStoriesRelease: getMyStoriesRelease,
    upsertStory: upsertStory,
    getEditStory: getEditStory,
    changeSeqChapter: changeSeqChapter,
    monetizeStory: monetizeStory,
    deleteStory: deleteStory,
    completeStory: completeStory,
    unCompleteStory: unCompleteStory,
  };
})();

export default myStoryService;
